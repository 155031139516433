.App {
    /* height: 100vh; */
    height: 92vh;
    overflow: hidden;
  }
  
  .header, .footer {
    position: fixed;
    width: 82%;
    margin-top: 10px;
    z-index: 1;
  }
  
  .header {
    top: 0;
    /* background-color: #f0f0f0; */
    background-color: #d2eefc;
    transition: background-color 0.3s ease-in-out;
  }
  
  .header.scrolled {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.11);
  }
  
  .footer {
    bottom: 0;
    background-color: #d2eefc;
    /* background-color: #f0f0f0; */
  }
  
  .content {
    overflow-y: auto;
    /*height: calc(100vh - 100px); *//* Adjust for header and footer height */
    height: calc(90vh - 90px); /* Adjust for header and footer height */
    margin-top: 180px; /* Adjust for header height */
    margin-bottom: 50px; /* Adjust for footer height */
    /*padding-top: 150px; /* Add padding to account for the fixed header */
  }